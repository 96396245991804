import React, { Fragment } from 'react';
import Layout from '../../layouts/Layout';
import { Helmet } from 'react-helmet'

const pocFormHandler = () => {

    const submitForm = () => {

        console.log('submitting....');
        document.querySelector('#form-result').innerHTML = '';
        const fName = document.querySelector("#First_Name__c").value;
        const lName = document.querySelector("#Last_Name__c").value;
        const instituteName = document.querySelector("#Institute_Name__c").value;
        const instituteEmail = document.querySelector("#Institute_Email__c").value;
        const vCountry =  document.querySelector("#Country__c").value;
        const vState = document.querySelector("#State__c").value;

        const formHandlerData = {
            handlerName : "academic_form_handler",    
            First_Name__c : fName,
            Last_Name__c : lName,
            Institute_Name__c : instituteName,
            Institute_Email__c : instituteEmail,
            Country__c : vCountry,
            State__c : vState
            }
    
            fetch('https://drm--poonamtbc.sandbox.my.salesforce-sites.com/handler/services/apexrest/handler', {
                method: 'POST',
                mode: 'cors',
                referrerPolicy: 'origin-when-cross-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'origin, X-Custom-Header',
                    'Access-Control-Allow-Method': 'POST',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(formHandlerData)
            
            }).then(res => res.json()).then(res => {
                console.log(res);
                document.querySelector('#form-result').innerHTML = res;
            });

    }

    return (
        <Layout>
            <Helmet>
           
            </Helmet>
            <div className="">
                <h1>Form Handler POC</h1>
                <div class="poc-form">
                    <form accept-charset="UTF-8"  class="form" id="pardot-form" method="post" >
                        <input id="handlerName" name="handlerName" type="hidden" value="academic_form_handler" />
                        <div>
                        <label for="First_Name__c">First Name</label>
                        <input type="text" name="First_Name__c" id="First_Name__c"></input>
                        </div>

                        <div>
                        <label for="Last_Name__c">Last Name</label>
                        <input type="text" name="Last_Name__c" id="Last_Name__c"></input>
                        </div>
                        
                        <div>
                        <label for="Institute_Name__c">Institute Name</label>
                        <input type="text" name="Institute_Name__c" id="Institute_Name__c"></input>
                        </div>

                        <div>
                        <label for="Institute_Email__c">Institute Email</label>
                        <input type="text" name="Institute_Email__c" id="Institute_Email__c"></input>
                        </div>

                        <div>
                        <label for="Country__c">Country</label>
                        <input type="text" name="Country__c" id="Country__c"></input>
                        </div>

                        <div>
                        <label for="State__c">State</label>
                        <input type="text" name="State__c" id="State__c"></input>
                        </div>

                        <div>
                            <input class="slds-button custom-btn" id="submit" type="button" value="Submit" onClick={submitForm} />
                        </div>
                        <div id="form-result"></div>
                    </form>
                </div>
            </div>
        </Layout>
    );

}

export default pocFormHandler;
